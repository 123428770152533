import { observer } from 'mobx-react'
import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { trackEvent } from '../../services/event-tracking'
import { RouteLink } from '../../utils/constants'
import { useStores } from '../../utils/stores'
import { LoadingPage } from '../Loading'
import { OnboardingPageWrapper } from '../auth/components/onboarding-page-wrapper'
import {
    AgeRangeStage,
    AreaOfCoachingStage,
    CoachGenderStage,
    CoachingLanguageStage,
    FindCoachStage,
    FunctionStage,
    GenderStage,
    IndustryStage,
    InitialStage,
    PositionStage,
    RateSatisfactionStage,
} from './stages'
import { TopValuesStage } from './stages/top-values'

const mixpanelTrackingDetails: {
    [key: number]: { name: string; section: 'onboarding' }
} = {
    0: { name: 'onboarding_intro', section: 'onboarding' },
    1: { name: 'select_gender', section: 'onboarding' },
    2: { name: 'select_age_range', section: 'onboarding' },
    3: { name: 'select_industry', section: 'onboarding' },
    4: { name: 'select_function', section: 'onboarding' },
    5: { name: 'select_position', section: 'onboarding' },
    6: { name: 'select_coaching_area', section: 'onboarding' },
    7: { name: 'select_top_values', section: 'onboarding' },
    8: { name: 'select_personal_values', section: 'onboarding' },
    9: { name: 'select_preferred_coach_gender', section: 'onboarding' },
    10: { name: 'select_coaching_language', section: 'onboarding' },
    11: { name: 'onboarding_completed', section: 'onboarding' },
}

export const OnboardingPage: React.FC = observer(() => {
    const { coachee, statics } = useStores()
    const navigate = useNavigate()
    const location = useLocation()
    const [isLoading, setIsLoading] = useState(true)
    const state = location.state as { canOnboard?: boolean } | undefined
    const stage = coachee.onboardingStage.stage

    useEffect(() => {
        statics.loadStatics()
    }, [statics])

    useEffect(() => {
        const subscription = coachee.getOnboardingOptions().subscribe({
            next: () => {
                setIsLoading(false)
            },
        })

        return () => {
            subscription.unsubscribe()
        }
    }, [coachee, statics])

    const updateFromUrl = useCallback(() => {
        const searchParams = new URLSearchParams(window.location.search)
        const urlStage = parseInt(searchParams.get('stage') || '0', 10)
        coachee.setOnboardingStage(urlStage)
        // location.search is vital for this to work. Remove it
        // and you will probably break the browser back button
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search])

    useEffect(() => {
        if (state && !state.canOnboard) {
            navigate(RouteLink.INDEX)
            return
        }

        if (
            coachee.hasCompletedAllRequiredOnboardingQuestions &&
            !coachee.coachee?.user.isDemoAccount
        ) {
            navigate(RouteLink.DASHBOARD, { replace: true })
            return
        }

        trackEvent('screen_viewed', mixpanelTrackingDetails[stage])

        const searchParams = new URLSearchParams(window.location.search)
        const urlStage = parseInt(searchParams.get('stage') || '0', 10)
        if (urlStage !== stage) {
            navigate('/onboarding?stage=' + stage, {
                replace: stage === 11 ? true : false,
            })
        }
        window.addEventListener('popstate', updateFromUrl)

        return () => {
            window.removeEventListener('popstate', updateFromUrl)
        }
        // If you add all the suggested params bellow you will break
        // the browser back button. Just warning you.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [coachee, navigate, stage, state, statics])

    const page = () => {
        switch (stage) {
            case 0:
                return <InitialStage currentStage={stage} />
            case 1:
                return <GenderStage currentStage={stage} />
            case 2:
                return <AgeRangeStage currentStage={stage} />
            case 3:
                return <IndustryStage currentStage={stage} />
            case 4:
                return <FunctionStage currentStage={stage} />
            case 5:
                return <PositionStage currentStage={stage} />
            case 6:
                return <AreaOfCoachingStage currentStage={stage} />
            case 7:
                return <TopValuesStage currentStage={stage} />
            case 8:
                return (
                    <RateSatisfactionStage
                        showMainError={false}
                        currentStage={stage}
                    />
                )
            case 9:
                return <CoachGenderStage currentStage={stage} />
            case 10:
                return <CoachingLanguageStage currentStage={stage} />
            case 11:
                return <FindCoachStage isOnboarding />

            default:
                return <InitialStage currentStage={stage} />
        }
    }

    return isLoading ? (
        <LoadingPage />
    ) : (
        <OnboardingPageWrapper>{page()}</OnboardingPageWrapper>
    )
})

export default OnboardingPage
