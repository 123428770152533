import { observer } from 'mobx-react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { RouteLink } from '../utils/constants'
import { useStores } from '../utils/stores'
import { OnboardingPageWrapper } from './auth/components/onboarding-page-wrapper'

export const LoadingPage: React.FC = observer(() => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { auth, coachee } = useStores()

    useEffect(() => {
        if (
            auth.authResponse &&
            coachee.hasCompletedAllRequiredOnboardingQuestions
        ) {
            navigate(RouteLink.DASHBOARD, {
                replace: true,
            })
            return
        }

        if (
            auth.authResponse &&
            coachee.coachee &&
            !coachee.hasCompletedAllRequiredOnboardingQuestions
        ) {
            navigate(RouteLink.ONBOARDING, {
                replace: true,
            })
            return
        }
    }, [
        auth.authResponse,
        coachee.coachee,
        coachee.hasCompletedAllRequiredOnboardingQuestions,
        navigate,
    ])

    return (
        <OnboardingPageWrapper>
            <span className="mt-14 text-center">{t('messages.loading')}</span>
        </OnboardingPageWrapper>
    )
})
