import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Lottie from 'react-lottie'
import tickAnimation from '../../../assets/animations/lottie/find-coach/checkmark.json'
import circleAnimation from '../../../assets/animations/lottie/find-coach/circle.json'
import confettiAnimation from '../../../assets/animations/lottie/find-coach/confetti.json'
import orbitAnimation from '../../../assets/animations/lottie/find-coach/orbit.json'

import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'
import { PrimaryButton } from '../../../components/buttons/primary-button'
import { ChangeMyCoachModel } from '../../../models/request/coachee/change-my-coach.model'
import { RouteLink } from '../../../utils/constants'
import { useStores } from '../../../utils/stores'
import { CoachModal } from '../../dashboard/modals/coach.modal'

interface FindCoachStageProps {
    reasonValues?: ChangeMyCoachModel
    isOnboarding?: boolean
}

const delay = (ms: number) => new Promise((res) => setTimeout(res, ms))

const orbitAnimationOptions = {
    loop: true,
    autoplay: true,
    animationData: orbitAnimation,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
}

const tickAnimationOptions = {
    loop: false,
    autoplay: true,
    animationData: tickAnimation,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
}

const circleAnimationOptions = {
    loop: false,
    autoplay: true,
    animationData: circleAnimation,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
}

const confettiAnimationOptions = {
    loop: false,
    autoplay: true,
    animationData: confettiAnimation,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
}

interface FoundProps {
    image: string | null
    isOnboarding?: boolean
}

const FoundCoachStage: React.FC<FoundProps> = ({
    image,
    isOnboarding = false,
}) => {
    const { coachee } = useStores()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [animationStage, setAnimationStage] = useState(1)
    const [isProfileOpen, setProfileIsOpen] = useState(false)

    const handleClick = () => {
        coachee.getCoachee().subscribe()
        navigate(RouteLink.DASHBOARD, { replace: true })
    }

    return (
        <>
            <div
                className={classNames(
                    'z-20 w-[90%] min-w-[300px] items-center justify-center flex flex-col max-w-[500px]',
                )}
            >
                <span
                    className={classNames(
                        isOnboarding ? 'text-4xl' : 'text-2xl',
                        'text-center font-medium',
                    )}
                >
                    {t('onboarding.findCoach.foundTitle')}
                </span>
                <p className="text-xl font-medium">
                    {t('onboarding.findCoach.foundSubTitle')}
                </p>
            </div>
            <div
                className={classNames(
                    'shadow-lg w-full h-[335] min-h-[340px] min-w-[300px] max-w-[500px]',
                )}
            >
                <div className="relative m-auto h-full pt-10">
                    <div className="flex inset-0 items-center justify-center ">
                        {image && (
                            <img
                                className="z-10 rounded-full m-auto "
                                src={image}
                                height={155}
                                width={155}
                            />
                        )}
                        <div
                            className={classNames(
                                'absolute z-20 top-[210px] justify-center items-center text-center pt-0',
                            )}
                        >
                            <div
                                className={classNames(
                                    isOnboarding ? 'text-4xl' : 'text-2xl ',
                                    'font-medium',
                                )}
                            >
                                {coachee.coachee?.currentCoach.user?.firstName}{' '}
                                {coachee.coachee?.currentCoach.user?.lastName}
                            </div>
                            <a
                                className="text-[#4c656d] pt-4 cursor-pointer"
                                onClick={() => setProfileIsOpen(true)}
                            >
                                {t('buttons.profile')}
                                <CoachModal
                                    isOpen={isProfileOpen}
                                    setIsOpen={setProfileIsOpen}
                                />
                            </a>
                        </div>
                    </div>
                    {animationStage == 1 && (
                        <div className="absolute top-[40px] flex inset-0 items-center justify-center">
                            <Lottie
                                options={circleAnimationOptions}
                                height={305}
                                width={330}
                                eventListeners={[
                                    {
                                        eventName: 'complete',
                                        callback: () =>
                                            setAnimationStage(
                                                animationStage + 1,
                                            ),
                                    },
                                ]}
                            />
                        </div>
                    )}
                    {animationStage == 2 && (
                        <div className="absolute top-[40px] flex inset-0 items-center justify-center">
                            <Lottie
                                options={confettiAnimationOptions}
                                height={305}
                                width={330}
                                eventListeners={[
                                    {
                                        eventName: 'complete',
                                        callback: () =>
                                            setAnimationStage(
                                                animationStage + 1,
                                            ),
                                    },
                                ]}
                            />
                        </div>
                    )}
                </div>
            </div>
            <PrimaryButton
                onClick={handleClick}
                className="w-[350px] h-[70px] mt-5 m-2 text-xl"
                type="submit"
            >
                <div className="flex justify-center items-center">
                    <span>{t('buttons.explore')}</span>
                </div>
            </PrimaryButton>
        </>
    )
}

export const FindCoachStage: React.FC<FindCoachStageProps> = ({
    reasonValues,
    isOnboarding = false,
}) => {
    const { t } = useTranslation()
    const { coachee, image } = useStores()
    const [matched, setMatched] = useState(false)
    const [animationStage, setAnimationStage] = useState(1)
    const [findingStage, setFindingStage] = useState(0)

    useEffect(() => {
        if (findingStage < 4) {
            delay(2.5 * 1000).then(() => {
                setFindingStage(findingStage + 1)
            })
        }
    }, [findingStage])

    const getCoachData = useCallback(() => {
        if (coachee.coachee && coachee.coachee.currentCoach._id) {
            image.getCoachPicture(coachee.coachee.currentCoach._id).subscribe()
        }
        setMatched(true)
    }, [coachee.coachee, image])

    useEffect(() => {
        // Change this later for rematching. But this is to protect
        // against the user pressing the back button and matching with
        // a different coach by mistake
        if (coachee.coachee && reasonValues) {
            coachee.reMatch(reasonValues).subscribe({
                complete() {
                    getCoachData()
                },
            })
        } else if (coachee.coachee && !coachee.coachee.currentCoach._id) {
            coachee.firstMatch().subscribe({
                complete() {
                    getCoachData()
                },
            })
        } else {
            getCoachData()
        }
        if (animationStage < 3) {
            delay(8 * 1000).then(() => {
                setAnimationStage(animationStage + 1)
            })
        }
        // if we add things to the array then some of the animations will
        // run multiple times and we don't want that
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div
            className={classNames(
                'flex flex-col justify-center items-center w-1/2 min-w-[270px] max-w-[500px]',
                isOnboarding ? 'mt-[30px]' : 'mt-0',
            )}
        >
            {animationStage !== 3 && (
                <>
                    <span
                        className={classNames(
                            isOnboarding ? 'text-4xl' : 'text-2xl',
                            'font-medium text-center mb-2',
                        )}
                    >
                        {t('onboarding.findCoach.title')}
                    </span>
                    {animationStage == 1 && (
                        <Lottie
                            options={orbitAnimationOptions}
                            height="90%"
                            width="100%"
                        />
                    )}
                    {animationStage == 2 && (
                        <Lottie
                            options={tickAnimationOptions}
                            height="90%"
                            width="100%"
                            eventListeners={[
                                {
                                    eventName: 'complete',
                                    callback: () =>
                                        setAnimationStage(animationStage + 1),
                                },
                            ]}
                        />
                    )}

                    <span
                        className={classNames(
                            isOnboarding && 'text-xl',
                            'text-center"',
                        )}
                    >
                        {t(
                            'onboarding.findCoach.findingSubTitles.' +
                                findingStage,
                        )}
                    </span>
                </>
            )}
            {animationStage === 3 && matched && (
                <FoundCoachStage
                    isOnboarding={isOnboarding}
                    image={image.coachPicture}
                />
            )}
        </div>
    )
}
