import { FormikHelpers } from 'formik'
import { t } from 'i18next'
import { observer } from 'mobx-react'
import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Subscription } from 'rxjs'
import { AddPhoneModel, TwoFaModel } from '../../../models/request'
import { RouteLink } from '../../../utils/constants'
import { cancelSubscriptions } from '../../../utils/misc'
import { useStores } from '../../../utils/stores'
import { TcPage } from '../../tc/tc.page'
import { OnboardingPageWrapper } from '../components/onboarding-page-wrapper'
import { SetPhoneForm, VerifyPhoneForm } from './forms'

enum Stage {
    SET_PHONE,
    VERIFY_PHONE,
    TC,
}

export const SignUpPage: React.FC = observer(() => {
    const navigate = useNavigate()
    const { auth, coachee } = useStores()
    const [stage, setStage] = useState<Stage>(Stage.SET_PHONE)
    const [subscriptions] = useState<Subscription[]>([])
    const location = useLocation()
    const state = location.state as { canSignUp?: boolean } | undefined
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        if (!auth.authResponse?.awaiting2fa) {
            const subscription = coachee.getOnboardingOptions().subscribe({
                next: () => {
                    setIsLoading(false)
                },
            })

            return () => {
                subscription.unsubscribe()
            }
        } else {
            setIsLoading(false)
        }
    }, [auth.authResponse?.awaiting2fa, coachee])

    useEffect(() => {
        return () => {
            cancelSubscriptions(subscriptions)
        }
    }, [subscriptions])

    const setPhoneNumber = useCallback(
        (values: AddPhoneModel, helpers: FormikHelpers<AddPhoneModel>) => {
            helpers.setSubmitting(true)
            cancelSubscriptions(subscriptions)

            const subscription = auth.setPhone(values).subscribe({
                next(response) {
                    if (response.ok) {
                        if (coachee.coachee) {
                            const newCoachee = { ...coachee.coachee }
                            newCoachee.user.phoneNumber = values.phone
                            newCoachee.user.phoneCountry = values.phoneCountry
                            coachee.setCoachee(newCoachee)
                        }

                        if (auth.authResponse?.awaiting2fa) {
                            setStage(Stage.VERIFY_PHONE)
                        } else {
                            setStage(Stage.TC)
                        }
                    }
                },
                complete() {
                    helpers.setSubmitting(false)
                },
            })

            subscriptions.push(subscription)
        },
        [auth, setStage, subscriptions],
    )

    const verifyPhoneNumber = useCallback(
        (values: TwoFaModel, helpers: FormikHelpers<TwoFaModel>) => {
            helpers.setSubmitting(true)
            cancelSubscriptions(subscriptions)

            const subscription = auth.validate2fa(values).subscribe({
                next(response) {
                    if (response.ok) {
                        setStage(Stage.TC)
                    }
                },
                complete() {
                    helpers.setSubmitting(false)
                },
            })

            subscriptions.push(subscription)
        },
        [auth, subscriptions, setStage],
    )

    const onPrevious = useCallback(() => {
        setStage(stage - 1)
    }, [stage])

    useEffect(() => {
        if (state && !state.canSignUp) {
            navigate(RouteLink.INDEX)
        }
    }, [navigate, state])

    return isLoading ? (
        <span className="mt-14 text-center">{t('messages.loading')}</span>
    ) : (
        <OnboardingPageWrapper>
            {stage === Stage.SET_PHONE && (
                <SetPhoneForm
                    title={
                        t('titles.setPhone', {
                            name: coachee.onboardingOptions?.firstName,
                        }) as string
                    }
                    subTitle={t('subTitles.setPhone') as string}
                    onSubmit={setPhoneNumber}
                />
            )}
            {auth.phone && stage === Stage.VERIFY_PHONE && (
                <VerifyPhoneForm
                    title={t('titles.twoFa') as string}
                    subTitle={auth.getObfiscatedPhoneNumber()}
                    isOnboarding={true}
                    onSubmit={verifyPhoneNumber}
                    onPrevious={onPrevious}
                />
            )}
            {auth.phone &&
                !auth.authResponse?.awaiting2fa &&
                stage === Stage.TC && (
                    <TcPage
                        title={t('titles.tc') as string}
                        subTitle={t('subTitles.tc') as string}
                    />
                )}
        </OnboardingPageWrapper>
    )
})

export default SignUpPage
