import { Formik, FormikHelpers } from 'formik'
import { observer } from 'mobx-react'
import React, { useCallback, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { PrimaryButton } from '../../../components/buttons/primary-button'
import { TextInput } from '../../../components/inputs/text-input'
import { PasswordLoginModel } from '../../../models/request'
import { RouteLink } from '../../../utils/constants'
import { useStores } from '../../../utils/stores'
import { validateModel } from '../../../utils/validation/validate-model'
import { AuthPageWrapper } from '../components/auth-page-wrapper'

export const VerifyPasswordPage: React.FC = observer(() => {
    const { t } = useTranslation()
    const { auth } = useStores()
    const [success, setSuccess] = useState(false)
    const navigate = useNavigate()
    const location = useLocation()
    const state = location.state as { canUsePassword?: boolean } | undefined

    useEffect(() => {
        if (!state?.canUsePassword) {
            navigate(RouteLink.INDEX, { replace: true })
        }
    }, [navigate, state])

    const onSubmit = useCallback(
        (
            values: PasswordLoginModel,
            helpers: FormikHelpers<PasswordLoginModel>,
        ) => {
            helpers.setSubmitting(true)
            auth.LogIn(values).subscribe({
                next(response) {
                    if (response.ok && response.data) {
                        setSuccess(true)
                        if (response.data.isPasswordGenerated) {
                            navigate(RouteLink.RESET_INITIAL_PASSWORD, {
                                replace: true,
                            })
                        } else {
                            navigate(RouteLink.DASHBOARD, { replace: true })
                        }
                    }
                },
                complete() {
                    helpers.setSubmitting(false)
                },
            })
        },
        [auth, navigate],
    )

    return (
        <AuthPageWrapper>
            <Formik
                validate={validateModel}
                initialValues={new PasswordLoginModel()}
                onSubmit={onSubmit}
            >
                {({ handleSubmit, isSubmitting, isValid, isValidating }) => (
                    <form
                        onSubmit={handleSubmit}
                        className="flex flex-col"
                        data-testid="form"
                    >
                        <header className="mb-10 flex flex-col justify-center items-center">
                            <h2 className="text-[2rem]">
                                {t('titles.verifyPassword')}
                            </h2>
                        </header>
                        <main className="space-y-3 mb-6">
                            <TextInput
                                name="password"
                                type="password"
                                label={t('fields.password.label') as string}
                                placeholder={
                                    t('fields.password.placeholder') as string
                                }
                                disabled={isSubmitting || success}
                            />
                        </main>
                        <footer>
                            <PrimaryButton
                                className="w-full"
                                type="submit"
                                loading={isSubmitting}
                                disabled={!isValid || isValidating}
                            >
                                <span>{t('buttons.continue')}</span>
                            </PrimaryButton>

                            <div className="flex justify-center items-center mt-8">
                                <span className="text-[1rem] text-center">
                                    {t('login.forgotPassword')}
                                    <br />
                                    <Trans t={t} i18nKey="login.reset">
                                        <Link
                                            to={RouteLink.FORGOTTEN_PASSWORD}
                                            style={{
                                                textDecoration: 'underline',
                                            }}
                                        />
                                    </Trans>
                                </span>
                            </div>
                        </footer>
                    </form>
                )}
            </Formik>
        </AuthPageWrapper>
    )
})
