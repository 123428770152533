import { useOktaAuth } from '@okta/okta-react'
import OktaSignIn from '@okta/okta-signin-widget'
import * as Sentry from '@sentry/browser'
import classNames from 'classnames'
import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import '../../../../node_modules/@okta/okta-signin-widget/dist/css/okta-sign-in.min.css'
import appleStoreImgFr from '../../../assets/icons/app-store-fr.png'
import appleStoreImgEn from '../../../assets/icons/apple-store.svg'
import googlePlayStoreImgFr from '../../../assets/icons/google-playstore-fr.svg'
import googlePlayStoreImgEn from '../../../assets/icons/google-playstore.svg'
import Logo from '../../../assets/logo/logo-dark.svg'
import { getOktaConfigFromUrl } from '../../../oktaConfig'
const OktaLogin = () => {
    const { t, i18n } = useTranslation()
    const language = i18n.language
    const playStoreAppLink =
        language === 'en'
            ? import.meta.env.VITE_APP_EN_PLAY_STORE_URL
            : import.meta.env.VITE_APP_FR_PLAY_STORE_URL

    const appStoreAppLink =
        language === 'en'
            ? import.meta.env.VITE_APP_EN_APP_STORE_URL
            : import.meta.env.VITE_APP_FR_APP_STORE_URL

    const googlePlayImg =
        language === 'en' ? googlePlayStoreImgEn : googlePlayStoreImgFr

    const appleStoreImg = language === 'en' ? appleStoreImgEn : appleStoreImgFr
    const [search] = useSearchParams()

    const { oktaAuth } = useOktaAuth()
    const widgetRef = useRef<HTMLDivElement>(null)

    const queryParams = new URLSearchParams(window.location.search)
    const encodedData = queryParams.get('details')
        ? queryParams.get('details')
        : null

    const error = queryParams.has('error') ? queryParams.get('error') : null

    useEffect(() => {
        if (!widgetRef.current) {
            return
        }

        const REDIRECT_URI = window.location.origin + '/login/callback'

        let clientId = null
        let issuer = null

        if (encodedData) {
            const decodedData = atob(encodedData)
            const split = decodedData.split(',')
            clientId = split[0]
            issuer = split[1]
        } else {
            const data = getOktaConfigFromUrl()
            clientId = data.clientId
            issuer = data.issuer
        }

        const widget = new OktaSignIn({
            redirectUri: REDIRECT_URI,
            logo: Logo,
            clientId: clientId,
            useClassicEngine: true,
            authParams: {
                issuer: issuer,
                scopes: ['openid', 'profile', 'email', 'offline_access'],
            },

            el: '#root',
            i18n: {
                en: {
                    'primaryauth.title': 'Sign in to SAMA',
                },
                fr: {
                    'primaryauth.title': 'Connectez-vous à SAMA',
                },
            },
        })
        if (!error) {
            oktaAuth.token
                .getWithRedirect({
                    responseType: ['id_token', 'token'],
                    scopes: ['openid', 'profile', 'email', 'offline_access'],
                    prompt: 'none',
                })
                .catch((error: Error) => {
                    Sentry.captureException(error)
                    console.log(error)
                })
        }

        if (error) {
            widget.showSignInAndRedirect().catch((error: Error) => {
                Sentry.captureException(error)
                console.log(error)
            })
        }

        // Note: Can't distinguish CORS error from other network errors
        const isCorsError = (err: { name: string; statusCode: any }) =>
            err.name === 'AuthApiError' && !err.statusCode

        widget.on('afterError', (_context: any, error: any) => {
            Sentry.captureException(error)
            if (isCorsError(error)) {
                console.error('CORS error')
            }
        })

        return () => widget.remove()
    }, [encodedData, error, oktaAuth])

    return (
        <>
            {search.has('do_login') && (
                <div>
                    <div ref={widgetRef} />
                    <div className="relative w-full flex items-center justify-center mt-8">
                        {error && (
                            <div className="w-full max-w-md text-[#ff0000] text-[17px] font-bold text-center bottom-1">
                                {decodeURIComponent(error)}
                            </div>
                        )}
                    </div>
                </div>
            )}
            {!search.has('do_login') && (
                <main className="flex flex-col items-center justify-center h-screen w-full">
                    <div
                        className={classNames(
                            'lg:w-[550px] px-8 h-[450px]',
                            'flex flex-col items-center justify-center bg-[#f9fbfc]',
                        )}
                    >
                        <img src={Logo} width={80} alt={t('alt.logo')} />
                        <h3 className="lg:text-[2rem] leading-[40px] text-center text-[1.5rem] mt-3">
                            {t('titles.logIn')}
                        </h3>
                        <span className="text-[15px] -mt-2 text-primary">
                            {t('subTitles.welcome')}
                        </span>
                        <a
                            href="#"
                            onClick={(e) => {
                                e.preventDefault()
                                window.open(
                                    window.location.href + '&do_login=true',
                                )
                            }}
                            className={classNames(
                                'px-[48px] mt-6 mb-10 py-3',
                                'rounded-full',
                                'bg-primary active:bg-accent-2',
                                'disabled:bg-[#f9fbfc]',
                                'disabled:text-[#a5b2b6] text-[#ffffff] text-[17px]',
                                'h-[50px] text-center w-[80%] lg:w-[380px]',
                            )}
                        >
                            {t('buttons.continue')}
                        </a>
                        <span className="text-[12px] font-[600] uppercase  text-primary">
                            {t('subTitles.appDownload')}
                        </span>
                        <div className="flex space-x-2 mt-3 items-center">
                            <a
                                target="_blank"
                                href={encodeURI(appStoreAppLink)}
                            >
                                <img
                                    src={appleStoreImg}
                                    alt="apply-playtore"
                                    width={150}
                                />
                            </a>
                            <a
                                href={encodeURI(playStoreAppLink)}
                                target="_blank"
                            >
                                <img
                                    src={googlePlayImg}
                                    alt="google-playStore"
                                    width={150}
                                />
                            </a>
                        </div>
                    </div>
                </main>
            )}
        </>
    )
}

export default OktaLogin
