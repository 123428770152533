import { ReactElement, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'

import errorIcon from '../../assets/icons/error.svg'

import * as Sentry from '@sentry/browser'
import Popup from './Popup'

export default function UserMediaError({
    when,
}: {
    when: boolean
}): ReactElement {
    const { t } = useTranslation()

    useEffect(() => {
        if (when === true) {
            Sentry.captureMessage(t('UserMediaError.body') as string)
        }
    }, [t, when])

    return (
        <Popup
            when={when}
            icon={errorIcon.toString()}
            title={t('UserMediaError.title')}
        >
            <ReactMarkdown>{t('UserMediaError.body') as string}</ReactMarkdown>
            <button onClick={() => window.location.reload()}>
                {t('common.refresh')}
            </button>
        </Popup>
    )
}
