import { Navigate } from 'react-router-dom'
import { RouteLink } from '../utils/constants'
import { LoadingPage } from './Loading'
import TestingPage from './TestingPage'
import { AccountPage } from './account/account.page'
import TwoFaPage from './auth/2fa/2fa.page'
import { LogInPage, VerifyPasswordPage } from './auth/log-in'
import LogOutPage from './auth/log-in/log-out.page'
import { OktaLoginCallback } from './auth/log-in/okta-login-callback'
import OktaLogin from './auth/log-in/okta-login.page'
import { MagicSignupPage } from './auth/magic-signup'
import ResetInitialPasswordPage from './auth/reset/reset-initial-password.page'
import { ResetPasswordRequestPage } from './auth/reset/reset-password-request.page'
import ResetPasswordPage from './auth/reset/reset-password.page'
import { SignUpPage } from './auth/signup'
import { BookingPage } from './booking/booking.page'
import { ChatPage } from './chat/chat.page'
import { DashboardPage } from './dashboard/dashboard.page'
import { LeaderboardPage } from './leaderboard/leaderboard.page'
import OnboardingPage from './onboarding/onboarding.page'

type Component = React.FC | React.LazyExoticComponent<any>
type RoutePath = RouteLink & string

export enum AuthRequirement {
    AUTHENTICATED,
    UNAUTHENTICATED,
    FULLY_AUTHENTICATED_ONBOARDING,
    FULLY_AUTHENTICATED,
    HAS_PHONE,
    AWAITING_2FA,
    NOT_AWAITING_2FA,
    DOES_NOT_HAVE_PHONE,
}

export type Route = [
    RoutePath[] /* Paths for route */,
    string /* Title */,
    Component /* Component to render */,
    AuthRequirement[],
    any? /* State */,
]

export const routes: Route[] = [
    [[RouteLink.LOG_IN], 'logIn', LogInPage, [AuthRequirement.UNAUTHENTICATED]],
    [
        [RouteLink.OKTA_LOGIN],
        'oktaLogin',
        OktaLogin,
        [AuthRequirement.UNAUTHENTICATED],
    ],
    [
        [RouteLink.OKTA_CALLBACK],
        'oktaCallback',
        OktaLoginCallback,
        [AuthRequirement.UNAUTHENTICATED],
    ],
    [
        [RouteLink.LOG_OUT],
        'logout',
        LogOutPage,
        [AuthRequirement.AUTHENTICATED],
    ],
    [
        [RouteLink.VERIFY_PASSWORD],
        'verifyPassword',
        VerifyPasswordPage,
        [AuthRequirement.UNAUTHENTICATED],
    ],
    [
        [RouteLink.TWO_FA],
        'twoFa',
        TwoFaPage,
        [AuthRequirement.HAS_PHONE, AuthRequirement.AWAITING_2FA],
    ],
    [
        [RouteLink.MAGIC_LOGIN],
        'magicLogin',
        MagicSignupPage,
        [AuthRequirement.UNAUTHENTICATED],
    ],
    [
        [RouteLink.SIGN_UP],
        'signUp',
        SignUpPage,
        [AuthRequirement.AUTHENTICATED],
    ],
    [
        [RouteLink.LOADING_PAGE],
        'loadingPage',
        LoadingPage,
        [AuthRequirement.FULLY_AUTHENTICATED],
    ],
    [
        [RouteLink.ONBOARDING],
        'onboarding',
        OnboardingPage,
        [AuthRequirement.FULLY_AUTHENTICATED_ONBOARDING],
    ],
    [
        [RouteLink.DASHBOARD],
        'dashboard',
        DashboardPage,
        [AuthRequirement.FULLY_AUTHENTICATED],
    ],
    [[RouteLink.CHAT], 'chat', ChatPage, [AuthRequirement.FULLY_AUTHENTICATED]],
    [
        [RouteLink.LEADERBOARD],
        'leaderboard',
        LeaderboardPage,
        [AuthRequirement.FULLY_AUTHENTICATED],
    ],
    [
        [RouteLink.ACCOUNT],
        'account',
        AccountPage,
        [AuthRequirement.FULLY_AUTHENTICATED],
    ],
    [
        [RouteLink.GOALS],
        'goals',
        DashboardPage,
        [AuthRequirement.FULLY_AUTHENTICATED],
    ],
    [
        [RouteLink.TASKS],
        'tasks',
        DashboardPage,
        [AuthRequirement.FULLY_AUTHENTICATED],
    ],
    [
        [RouteLink.NOTES],
        'notes',
        DashboardPage,
        [AuthRequirement.FULLY_AUTHENTICATED],
    ],
    [
        [RouteLink.RESET],
        'resetPassword',
        ResetPasswordPage,
        [AuthRequirement.UNAUTHENTICATED],
    ],
    [
        [RouteLink.FORGOTTEN_PASSWORD],
        'forgottenPassword',
        ResetPasswordRequestPage,
        [AuthRequirement.UNAUTHENTICATED],
    ],
    [
        [RouteLink.RESET_INITIAL_PASSWORD],
        'resetPassword',
        ResetInitialPasswordPage,
        [AuthRequirement.AUTHENTICATED],
    ],
    [
        [RouteLink.NEW_BOOKING],
        'newBooking',
        BookingPage,
        [AuthRequirement.FULLY_AUTHENTICATED],
    ],
    [
        [RouteLink.TESTING],
        'testing',
        TestingPage,
        [AuthRequirement.UNAUTHENTICATED],
    ],
    [
        [RouteLink.INDEX],
        'redirecting',
        () => <Navigate to={RouteLink.DASHBOARD} replace={false} />,
        [AuthRequirement.FULLY_AUTHENTICATED],
    ],
    [
        [RouteLink.ANY],
        'redirecting',
        () => <Navigate to={RouteLink.DASHBOARD} replace={false} />,
        [],
    ],
]
